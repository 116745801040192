import React, { useEffect, useState } from 'react';
import * as styles from './index.module.scss';
// import Logo from '../logo';
// import ButtonLink from '../button/button-link';
// import { Link } from 'gatsby';
// import ButtonInput from '../inputs/button-input';
import ButtonTypeform from '../button/button-typeform';
import WipeLink from '../nav-transitions/wipe-link';
import navLinks from '../../constants/navLinks';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/email-animation-branded.json';
import axios from 'axios';
import SimpleFade from '../animate/simple-fade';
import { isMobile, isTablet } from 'react-device-detect';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const LocationBlock = ({ location }) => {
  return (
    <div className={`${styles.column} ${styles.location}`}>
      <a
        href={location.link}
        target='_blank'
        className={`title-XS ${styles.locationTitle}`}
        rel='noreferrer'
      >
        {location.name}
      </a>
      <p className={`body-XS ${styles.address}`}> {location.location}</p>
    </div>
  );
};

const SocialIcon = ({ icon, url }) => {
  return (
    <a
      target='_blank'
      href={url}
      className={`${icon} ${styles.socialIcon}`}
      rel='noreferrer'
    />
  );
};

const Footer = () => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const locations = [
    {
      link: 'https://goo.gl/maps/CN2XxN71TAJPpDQj6',
      name: 'dublin',
      location: 'Lucky Beard Dublin,15 Harcourt Street,Dublin 2,D02 XY47',
    },
    {
      link: 'https://goo.gl/maps/1ZmEhBeomZvkNXNW6',
      name: 'london',
      location:
        'Rooms F10 & F11, First Floor, West Wing, Somerset House, Strand, London WC2R 1LA',
    },
    {
      link: 'https://goo.gl/maps/dLUpJqRwzJwsREz17',
      name: 'pretoria',
      location:
        'Southdowns Ridge Office Park, Cnr. Nelmapius & John Vorster Ave, Irene,Centurion 0123',
    },
    {
      link: 'https://goo.gl/maps/85S3erqAz1yAAEK28',
      name: 'johannesburg',
      location: '37 Bath Avenue, Rosebank, Johannesburg, 2193',
    },
  ];

  const breakpoints = useBreakpoint();
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(false);

  const submitForm = async (data) => {
    try {
      reset();
      setSending(true);
      const results = await Promise.all([
        axios.post(process.env.GATSBY_EMAIL_FOOTER, data, {
          headers: {
            'x-api-key': process.env.GATSBY_EMAIL_SECRET,
            'Content-Type': 'application/json',
          },
        }),
        new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 200); // use time to set min wait time
        }),
      ]);
      setSending(false);
    } catch (error) {
      console.warn('Something went wrong', error);
      setSending(false);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  };

  return (
    <div
      className={`${styles.footerWrapper} ${
        (isMobile || isTablet) && styles.tabletAndSmaller
      }`}
    >
      {/* uncommented for now until other pages are in */}
      <div className={`${styles.navLinks}`}>
        {navLinks.flat().map((item) => {
          if (item.type === 'chat') return null;

          return (
            <div
              key={`nav-link-${item.name}`}
              className={styles.navLinkWrapper}
            >
              <WipeLink to={`${item.path}`}>
                <span className={`title-XXS ${styles.navLink}`}>
                  {item.name}
                </span>
              </WipeLink>
            </div>
          );
        })}
      </div>

      <div className={`${styles.sectionWrapper}`}>
        <div className={`${styles.left}`}>
          {/* <div className={`${styles.logoWrapper}`}>
            <Logo light />
          </div> */}

          <div className={`${styles.contactBlock}`}>
            <div className={`${styles.getInTouch}`}>
              <ButtonTypeform type='secondary' text='Talk to us' />
            </div>

            <div className={`body-S ${styles.contactInfo}`}>
              <a
                className={`body-S ${styles.enquiryEmail}`}
                href='mailto:enquiries@luckybeard.com'
              >
                enquiries@luckybeard.com
              </a>
              {/* <a className={`${styles.enquiryNumber}`} href='tel:+353872515181'>
                +353 (0) 87 251 5181
              </a> */}
            </div>

            <div className={`${styles.socialLinks}`}>
              {breakpoints.sm ? (
                <div className={`${styles.mobileSocialLinks}`}>
                  <div className={`${styles.socialHalf}`}>
                    <SocialIcon
                      url={
                        'https://www.behance.net/luckybeard?tracking_source=search_users%7Clucky%20beard'
                      }
                      icon={'icon-behance'}
                    />
                    <SocialIcon
                      url={
                        'https://www.youtube.com/channel/UCuIm1nTss1aFQsLLMW_QU6Q'
                      }
                      icon={'icon-youtube'}
                    />
                    <SocialIcon
                      url={'https://dribbble.com/LuckyBeard'}
                      icon={'icon-dribble'}
                    />
                  </div>
                  <div className={`${styles.socialHalf}`}>
                    {' '}
                    <SocialIcon
                      url={'https://www.instagram.com/luckybeardglobal/?hl=en'}
                      icon={'icon-instagram'}
                    />
                    <SocialIcon
                      url={'https://za.linkedin.com/company/lucky-beard'}
                      icon={'icon-linked-in2'}
                    />
                    <SocialIcon
                      url={'https://www.facebook.com/luckybeardglobal'}
                      icon={'icon-facebook2'}
                    />{' '}
                  </div>
                </div>
              ) : (
                <>
                  <SocialIcon
                    url={
                      'https://www.behance.net/luckybeard?tracking_source=search_users%7Clucky%20beard'
                    }
                    icon={'icon-behance'}
                  />
                  <SocialIcon
                    url={
                      'https://www.youtube.com/channel/UCuIm1nTss1aFQsLLMW_QU6Q'
                    }
                    icon={'icon-youtube'}
                  />
                  <SocialIcon
                    url={'https://dribbble.com/LuckyBeard'}
                    icon={'icon-dribble'}
                  />
                  <SocialIcon
                    url={'https://www.instagram.com/luckybeardglobal/?hl=en'}
                    icon={'icon-instagram'}
                  />
                  <SocialIcon
                    url={'https://za.linkedin.com/company/lucky-beard'}
                    icon={'icon-linked-in2'}
                  />
                  <SocialIcon
                    url={'https://www.facebook.com/luckybeardglobal'}
                    icon={'icon-facebook2'}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <div className={`${styles.right}`}>
          <div className={styles.locations}>
            {breakpoints.md ? (
              <>
                {locations.map((loc) => (
                  <LocationBlock key={loc.link} location={loc} />
                ))}
              </>
            ) : (
              <>
                <div className={`${styles.half}`}>
                  <LocationBlock location={locations[0]} />
                  <LocationBlock location={locations[1]} />
                </div>
                <div className={`${styles.half}`}>
                  <LocationBlock location={locations[2]} />
                  <LocationBlock location={locations[3]} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.legalSection}>
        <span className={`body-XS ${styles.copyright}`}>
          © 2023 by Lucky Beard. All rights reserved.
        </span>

        <div className={styles.right}>
          <WipeLink to='/privacy-policy'> Privacy Policy</WipeLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
