// extracted by mini-css-extract-plugin
export var footerWrapper = "index-module--footer-wrapper--lcu0+";
export var error = "index-module--error--PG3gC";
export var navLinks = "index-module--nav-links--OhQCS";
export var navLinkWrapper = "index-module--nav-link-wrapper--vDeuR";
export var navLink = "index-module--nav-link--l7Qys";
export var sectionWrapper = "index-module--section-wrapper--e9Brt";
export var left = "index-module--left--3qtAM";
export var logoWrapper = "index-module--logo-wrapper--nl1b0";
export var contactBlock = "index-module--contact-block--PFcjZ";
export var getInTouch = "index-module--get-in-touch--vfqp+";
export var contactInfo = "index-module--contact-info--vnEGv";
export var enquiryEmail = "index-module--enquiry-email--82BNt";
export var enquiryNumber = "index-module--enquiry-number--j4KBx";
export var socialLinks = "index-module--social-links--qo8pT";
export var mobileSocialLinks = "index-module--mobile-social-links---XOyr";
export var socialHalf = "index-module--social-half--knaqc";
export var socialIcon = "index-module--social-icon--VL4-9";
export var right = "index-module--right--dyo8g";
export var locations = "index-module--locations--CyHnM";
export var location = "index-module--location--Z67Zb";
export var half = "index-module--half--jwZJA";
export var workWithUs = "index-module--work-with-us--3mmnG";
export var locationTitle = "index-module--location-title--F2Mjt";
export var address = "index-module--address--PLoUv";
export var column = "index-module--column--4kQHC";
export var social = "index-module--social--FGulR";
export var title = "index-module--title--2Rtpn";
export var facebook = "index-module--facebook--z15Zn";
export var instagram = "index-module--instagram--TGoy1";
export var linkedIn = "index-module--linked-in--bOfjr";
export var youtube = "index-module--youtube--FAHUg";
export var legalSection = "index-module--legal-section--wdhci";