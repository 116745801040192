const navLinks = [
  [
    { name: 'home', path: '/',type: 'path' },
    { name: 'what we do', path: '/what-we-do', type: 'path' },
  ],
  [
    { name: 'our work', path: '/our-work',type: 'path' },
    {
      name: 'careers',
      path: '/careers',
      type: 'path'
    },
  ],
  [
    { name: 'contact us', path: '#', type: 'chat' }
  ]
];

export default navLinks;
